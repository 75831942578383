<template>
  <div class="highlighted-value__item">
    <h3 v-if="title">{{ title }}</h3>
    <p
      class="highlighted-value__value"
      :class="{ row: row, column: column, 'no-data': !value }">
      {{ value || "-" }}
      <span
        class="highlighted-value__sub-data row align-center"
        v-if="subValue">
        <span class="highlighted-value__sub-data__title" v-if="subTitle">
          {{ subTitle }}
        </span>
        <span
          class="highlighted-value__sub-data__value"
          :class="{
            safe: subValueSafe,
            alert: subValueAlert,
          }"
          >{{ subValue }}</span
        >
      </span>
    </p>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
      },
      value: {
        type: String,
      },
      subTitle: {
        type: String,
      },
      subValue: {
        type: [Number, String],
      },
      row: {
        type: Boolean,
        default: false,
      },
      column: {
        type: Boolean,
        default: true,
      },
      subValueSafe: {
        type: Boolean,
        default: false,
      },
      subValueAlert: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss">
  .highlighted-value {
    &__item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      h3 {
        width: 100%;
        margin-top: 0;
        margin-bottom: 1rem;
        border-left: $card_title_border;
        padding-left: $card_title_text_space_from_left_border;
        font-weight: $card_font_weight;
        font-size: $card_font_size;
        @media screen and (max-width: $small_mobile_width) {
          font-size: 1rem;
        }
      }
    }
    &__value {
      min-width: 100px;
      font-size: 1.6rem;
      margin: 0;
      line-height: 1.5rem;
      gap: 0.85rem !important;
      min-height: 60px;
      font-weight: $card_font_weight;

      &.no-data {
        color: $col_faded;
      }
    }
    &__sub-data {
      font-size: 1rem;
      line-height: 1rem;
      color: #aaa;
      &__title {
        width: 80px;
        font-size: 0.85rem;
      }
      &__value {
        flex-grow: 1;
        font-weight: bold;
        text-align: left;
        &.safe {
          color: $col_good_contrast_green;
        }
        &.alert {
          color: $col_alpha;
        }
      }
    }
  }
</style>
